.item_accountprofile {
    display: flex;
    margin-top: 25px;
    background-color: white;
    flex-flow: row wrap;
}
.account_profile {
    margin-top: 10px;
    width: 60%;
    padding: 0 10px;
}
.titleitem {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}
.edit_account_bankl {
    margin-top: 10px;
    width: 40%;
    padding: 0 10px;
}
.profile-baocaodaily,
.profile-thongke {
    width: 50%;
    padding: 0 10px;
}
.item_accountprofile table {
    min-width: unset !important;
    clear: both;
    width: 100% !important;
}
@media (max-width: 800px) {
    .account_profile,
    .edit_account_bankl,
    .profile-baocaodaily,
    .profile-thongke {
        width: 100%;
    }
}
.btn_setting {
    color: #fff;
    background-color: #1f2937;
    border-color: #1f2937;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(17, 24, 39, 0.075);
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0.0625rem solid transparent;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
}
.setting {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}
.form_group {
    --bs-gutter-x: -0.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}
.form_col {
    margin-bottom: 1rem !important;
    flex: 0 0 auto;
    width: 50%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
}
.setting .form_col {
    width: 33.33%;
}
.setting .form_col.col2 {
    width: 50%;
}
@media (max-width: 800px) {
    .setting .form_col {
        width: 50%;
    }
}
.input_setting {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6b7280;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid #d1d5db;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.detail_user {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
}
.detail_user input,
.detail_user select {
    padding: 1px 5px !important;
}
.detail_user input {
    width: 100px;
}
.username_ {
    font-weight: 600;
    padding: 5px;
    font-size: 0.8rem;
    text-align: left;
}
.username_:has(button) {
    padding: 0;
}
.detail_user button {
    padding: 0 5px !important;
}
.detail_user > div:nth-child(2) {
    font-weight: normal;
}
.modal {
    display: none;
    animation: ModalGrow ease-in 0.2s;
}

.modalheader {
    display: flex;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 28px;
    font-weight: 600;
    font-size: 25px;
}
.modalheader h3 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
}
.modalheader span {
    font-size: 1.5rem;
    line-height: 100%;
    margin: auto 0;
    cursor: pointer;
    color: var(--shop_be-color);
}

.modalformsocials a:hover,
.modalhelp a:hover,
.btn:hover,
.modalheader span:hover {
    opacity: 0.7;
}

.modalinner {
    padding: 15px;
    background-color: white;
    border-radius: 10px;
}
.item_btn_form {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.modalforminput {
    width: 100%;
    height: 40px;
    display: block;
    margin-bottom: 28px;
    font-size: 1.4em;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.btn {
    height: 40px;
    width: 100%;
    background-color: #efbf6e;
    color: var(--white-color);
    font-size: 1.4rem;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
}

.modalhelp {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
}
.modalhelp a {
    font-size: 1.3rem;
    text-decoration: none;
}

.modalformsocials {
    margin-bottom: 1rem;
    padding: 2rem 0;
    display: flex;
}
.modalformsocials a {
    flex: 1;
    text-align: center;
    text-decoration: none;
    font-size: 1.4rem;
    line-height: 40px;
    color: var(--white-color);
    border-radius: 4px;
}
.btn-with-facebook {
    background-color: #1877f2;
    margin-right: 1rem;
}
.btn-with-google {
    background-image: linear-gradient(to right, #ea4335, #fbbe0d, #8ccd9d, #4285f4);
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999999999999999999;
    display: flex;
}

.modaloverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.modalbody {
    background-color: var(--white-color);
    position: relative;
    margin: auto;
    border-radius: 5px;
    width: 85%;
    max-width: 400px;
}
.closelogin {
    color: white;
    position: absolute;
    font-size: 30px;
    right: 10px;
    top: 50px;
    font-weight: bold !important;
    cursor: pointer;
}
.closelogin:hover {
    color: rgb(239 191 110);
}
.modalformgroup.d-flex {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.modalformgroup.d-flex > div {
    flex: 1;
    text-align: left;
}
.modalformgroup.d-flex > div:first-child {
    flex: 0 0 120px;
    text-align: left;
}
.modalbody:has(.modalformgroup.d-flex) {
    width: 500px;
}
select,
textarea {
    border: 1px solid #ccc !important;
    padding: 5px;
    border-radius: 5px;
    max-width: 100%;
}
.form-admin input,
.form-admin textarea {
    width: 100%;
}
.ql-editor {
    max-height: 300px;
    overflow: auto;
}